export interface PersonalManager {
    name?: string;
    phone?: string;
    email?: string;
    image_url?: string;
    telegram_username?: string;
    substitute_to?: string;
}

export enum ClientType {
    Investor = 'investor',
    Borrower = 'borrower',
    Partner = 'partner',
}

export enum InvestorUserType {
    Individual = 'individual',
    Company = 'company',
}

/**
 * Модель описывает базовые характеристики пользователя в системе.
 */
export interface IBaseAccountUserApiModel {
    /**
     * Уникальный идентификатор общего пользователя (User).
     */
    user_id: number;
    /**
     * ФИО пользователя.
     */
    name: string;
    /**
     * Уникальный идентификатор счета (Investor / Borrower / Partner) по-умолчанию общего пользователя.
     */
    id: number;
}

interface IInvestorPortfolioStatApiModel {
    /**
     * Сумма баланса на счете.
     */
    amount: number;
}

export interface IInvestorPortfolioApiModel extends IInvestorPortfolioStatApiModel {
    /**
     * Уникальный идентификатор портфеля пользователя (Investor Id)
     */
    id: number;
    /**
     * Название портфеля.
     */
    name: string;
}

export interface IInvestorAccountUserApiModel extends IBaseAccountUserApiModel, IInvestorPortfolioStatApiModel {
    /**
     * Тип аккаунта инвестора.
     */
    type?: InvestorUserType;
    /**
     * Список доступных портфелей у инвестора.
     */
    portfolios: IInvestorPortfolioApiModel[];
}