import { stringifyUrl } from 'query-string';
import {
    ClientType,
    IBaseAccountUserApiModel,
} from '@core/models/account';
import { ApiDataResponse } from '@core/models/api';
import { apiGetRequest } from '@core/services/api';
import {
    MOCK_USER_SIDE_PANEL_BORROWER_USERS,
    MOCK_USER_SIDE_PANEL_INVESTOR_USERS,
    MOCK_USER_SIDE_PANEL_PARTNERS_USERS,
} from './services.mock';
import { testResponse } from '@core/services/api.mock';

export const CLIENT_TYPE_API_MAP: Record<ClientType, string> = {
    [ClientType.Investor]: 'invest',
    [ClientType.Borrower]: 'lend',
    [ClientType.Partner]: 'partner',
};


export async function apiGetCabinetUsers<TUser extends IBaseAccountUserApiModel = IBaseAccountUserApiModel>(clientType: ClientType): Promise<ApiDataResponse<TUser[]>> {
    if (process.env.TARGET_ENV === 'test') {
        const mappedMock = {
            [ ClientType.Investor ]: MOCK_USER_SIDE_PANEL_INVESTOR_USERS,
            [ ClientType.Borrower ]: MOCK_USER_SIDE_PANEL_BORROWER_USERS,
            [ ClientType.Partner ]: MOCK_USER_SIDE_PANEL_PARTNERS_USERS,
        };

        return testResponse(mappedMock[clientType]);
    }

    try {
        return await apiGetRequest(`~/${CLIENT_TYPE_API_MAP[clientType]}/api/account/users/v2`);
    } catch {
        // Ошибки возникают в основном из-за нехватки доступов и это приводит к забиваю логов.
        // Обрабатываем это как обычное состояние и не логируем.
        return {
            status: 'OK',
            data: [],
        };
    }
}

export function getAccountSwitchEndpoint(clientType: ClientType, accountId: number): string {
    return stringifyUrl({
        url: `/${CLIENT_TYPE_API_MAP[clientType]}/switch`,
        query: {
            id: accountId,
        },
    });
}

export function getAccountCreateEndpoint(clientType: ClientType, attributes = {}): string {
    return stringifyUrl({
        url: `/${CLIENT_TYPE_API_MAP[clientType]}/create`,
        query: attributes,
    });
}