// Root handlers
import * as sharedToasts from '@frontend/jetlend-core/src/ducks/toasts';
import * as sharedNetwork from '@frontend/jetlend-core/src/ducks/api/common';
import * as sharedUserSidePanel from '@frontend/jetlend-core/src/features/CabinetUserSidePanel/ducks';
import * as form from './form';

// Common
import * as commonRouter from './router';
import * as commonSms from './common/sms';
import * as commonOauth from './common/oauth';
import * as commonRegistration from './common/registration';
import * as commonLogin from './common/login';
import * as commonReset from './common/reset';
import * as commonCreate from './common/create';
import * as commonAccount from './common/account';

// Investors
import * as investors from './investors/investors';
import * as autoinvest from './autoinvest';

// Borrowers
import * as borrowers from './borrowers/borrowers';

// Partners
import * as partners from './partners/partners';

// Analytics
import * as analytics from './common/analytics';

// Academy
import * as academyCourse from './academy/course';

// Account
import * as account from './account';

// Documents
import * as documents from './documents/documents';

// Investments
import * as investments from './investments';

// Tests
import * as tests from './tests';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    sharedToasts,
    sharedNetwork,
    sharedUserSidePanel,
    form,

    commonRouter,
    commonSms,
    commonOauth,
    commonRegistration,
    commonLogin,
    commonReset,
    commonCreate,
    commonAccount,

    investors,
    borrowers,
    partners,
    autoinvest,
    investments,

    academyCourse,

    analytics,
    account,
    documents,

    tests,
};
