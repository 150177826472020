import { simpleApiHandler } from '@core/ducks/api';
import { modalHandlerV2 } from '@core/ducks/v2/modal';
import { apiGetCabinetUsers } from './services';
import {
    ClientType,
    IInvestorAccountUserApiModel,
} from '@core/models/account';

export const VERSION = 2;
export const PREFIX = 'shared/cabinet_user_side_panel';

/**
 * Хендлер управления открытием боковой панелью со списком пользователей.
 */
export const userSidePanelHandler = modalHandlerV2(PREFIX, 'modal');

/**
 * Хендлер получения списка пользователей инвесторов.
 */
export const accountInvestorUsersHandler = simpleApiHandler(
    PREFIX,
    'investor',
    () => apiGetCabinetUsers<IInvestorAccountUserApiModel>(ClientType.Investor),
    undefined,
    undefined,
    {
        cacheTag: 'users',
        errorHandler: {
            skipAccessDeniedHandling: true,
        },
    }
);

/**
 * Хендлер получения списка пользователей заемщиков.
 */
export const accountBorrowerUsersHandler = simpleApiHandler(
    PREFIX,
    'borrowers',
    () => apiGetCabinetUsers(ClientType.Borrower),
    undefined,
    undefined,
    {
        cacheTag: 'users',
        errorHandler: {
            skipAccessDeniedHandling: true,
        },
    }
);

/**
 * Хендлер получения списка пользователей партнеров.
 */
export const accountPartnerUsersHandler = simpleApiHandler(
    PREFIX,
    'partners',
    () => apiGetCabinetUsers(ClientType.Partner),
    undefined,
    undefined,
    {
        cacheTag: 'users',
        errorHandler: {
            skipAccessDeniedHandling: true,
        },
    }
);