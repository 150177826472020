import {
    IBaseAccountUserApiModel,
    IInvestorAccountUserApiModel,
    InvestorUserType,
} from '@core/models/account';

export const MOCK_USER_SIDE_PANEL_INVESTOR_USERS: IInvestorAccountUserApiModel[] = [
    {
        user_id: 1,
        id: 1,
        name: 'Хорошев Роман Петрович',
        type: InvestorUserType.Individual,
        amount: 20804234.54,
        portfolios: [
            {
                id: 1,
                name: 'Портфель №1',
                amount: 16804234.49,
            },
            {
                id: 2,
                name: 'Портфель №2',
                amount: 4000000.05,
            },
        ],
    },
    {
        user_id: 2,
        id: 3,
        name: 'ООО “Левые схемы и процедуры”',
        type: InvestorUserType.Company,
        amount: 4000000.05,
        portfolios: [
            {
                id: 3,
                name: 'Портфель №1',
                amount: 4000000.05,
            },
        ],
    },
    {
        user_id: 3,
        id: 10,
        name: 'ООО “Пустой портфель”',
        type: InvestorUserType.Company,
        amount: 11000,
        portfolios: [
            {
                id: 10,
                name: 'Портфель №1',
                amount: 5000,
            },
            {
                id: 11,
                name: 'Портфель №1',
                amount: 6000,
            },
        ],
    },
];

export const MOCK_USER_SIDE_PANEL_BORROWER_USERS: IBaseAccountUserApiModel[] = [
    {
        user_id: 4,
        id: 4,
        name: 'ООО “Солнышко”',
    },
];

export const MOCK_USER_SIDE_PANEL_PARTNERS_USERS: IBaseAccountUserApiModel[] = [
    {
        user_id: 5,
        id: 5,
        name: 'ООО “Фрод номиналович”',
    },
];



