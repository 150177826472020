import {
    put,
    takeEvery,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { Action } from 'redux';
import { createType } from '../core';
import { objectHandler } from '../object';
import {
    IBaseHandler,
    SimpleSelector,
} from '../props';

export type ModalOpenActionFn<TState> = TState extends undefined
    ? () => Action
    : (state: TState) => Action;

/**
 * Интерфейс хендлера модального окна.
 *
 * @see {@link modalHandlerV2}
 * @see {@link confirmationModalHandlerV2}
 */
export interface IModalHandlerV2<TState = undefined> extends IBaseHandler<TState> {
    /**
     * @event
     * Redux Action. Событие открытия модального окна.
     * @param state Состояние модального окна, может быть пустым.
     */
    OPEN: string;
    /**
     * Открывает модальное окно.
     * @param state Состояние модального окна
     */
    open: ModalOpenActionFn<TState>;
    /**
     * @event
     * Redux Action. Событие закрытия модального окна.
     */
    CLOSE: string;
    /**
     * Закрывает модальное окно.
     */
    close: () => Action;
    /**
     * Selector. Возвращает флаг открытия модального окна.
     */
    isOpen: SimpleSelector<boolean>;
}

/**
 * Модель внутреннего состояния хендлера модального окна.
 * @private
 */
export interface IModalInternalState<TState = undefined> {
    state?: TState;
    isOpen: boolean;
}

/**
 * Реализует хендлер {@link IModalHandlerV2} для работы с модальными окнами.
 *
 * @category Duck Handlers
 *
 * @param prefix Префикс duck модуля
 * @param name Имя хендлера внутри модуля
 * @returns Новый экземпляр управления модальным окном
 *
 * @see {@link IModalHandlerV2}
 * @see {@link useModalHandlerV2}
 * @see {@link confirmationModalHandlerV2}
 *
 * @example
 * // ducks.ts
 * export interface ISprintDetailsModalState {
 *     sprint: ISprintApiModal;
 * }
 *
 * export const sprintDetailsModalHandler = modalHandlerV2<ISprintDetailsModalState>('modal', 'sprintDetailsModal');
 *
 * // Component.tsx
 *
 * const open = useActions(sprintDetailsModalHandler.open);
 * const didSprintClicked = useCallback((sprint: ISprintApiModel) => {
 *      open({ sprint });
 * }, [ open ]);
 *
 * // Modal.tsx
 *
 * const [ isOpen, close, state ] = useModalHandlerV2(sprintDetailsModalHandler);
 */
export function modalHandlerV2<TState = undefined>(
    prefix: string,
    name: string
): IModalHandlerV2<TState> {
    const handler = objectHandler<IModalInternalState<TState>>(prefix, name);

    const {
        update,
        selector,
        reducer,
        reducerInfo,
    } = handler;

    const OPEN = createType(prefix, `${name}/OPEN`);
    const CLOSE = createType(prefix, `${name}/CLOSE`);

    const open = (state?: TState) => ({
        type: OPEN,
        state,
    });

    const close = () => ({ type: CLOSE });

    function* openSaga({ state }) {
        yield put(update({
            isOpen: true,
            state,
        }));
    }

    function* closeSaga() {
        yield put(update({ isOpen: false }));
    }

    const effects = [
        takeEvery(OPEN as any, openSaga),
        takeEvery(CLOSE as any, closeSaga),
    ];

    return {
        OPEN,
        open: (open as any) as ModalOpenActionFn<TState>,
        CLOSE,
        close,
        isOpen: createSelector(selector, internalState => internalState?.isOpen === true),
        selector: createSelector(selector, internalState => internalState?.state),
        reducer,
        reducerInfo,
        effects,
    };
}
