import {
    ICommonNetworkManagerConfig,
    INetworkManager,
} from './types';

class NetworkManagerImpl implements INetworkManager {
    config: ICommonNetworkManagerConfig;

    constructor() {
        this.config = {

        };
    }

    resolveUrl(url: string) {
        if (typeof this.config.urlResolver === 'function') {
            return this.config.urlResolver(this, url);
        }

        return url.startsWith('~')
            ? `${this.config.domainUrl ?? ''}${url.substring(1)}`
            : `${this.config.apiEndpoint ?? ''}${url}`;
    }
}

/**
 * Синглтон сетевого менеджера.
 *
 * Содержит глобальные настройки сети и вспомогательные функции.
 *
 * @see {@link INetworkManager}
 *
 * @category Services
 */
const NetworkManager = new NetworkManagerImpl();

export default NetworkManager;