import axios, { AxiosRequestConfig } from 'axios';
import NetworkManager from './NetworkManager';
import { FileList as PolyfillFileList } from './files';

/**
 * Внутренний метод для отправки любых запросов.
 */
async function apiRequest<TResponse>(method, path, queryParams = undefined, payloadData = undefined, extra: Partial<AxiosRequestConfig> = {}): Promise<TResponse> {
    const headers = {
        // Коммуникация с АПИ идет в формате JSON
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true,
    };

    const requestConfig: AxiosRequestConfig = {
        ...extra,
        timeout: 60000, // 1 Minute
        method,
        // Преобразуем путь запроса в полноценный URL
        url: NetworkManager.resolveUrl(path),
        // В axios используются разные параметры для передачи данных для GET и остальных методов
        data: payloadData,
        params: queryParams,
        withCredentials: true,
        headers: {
            ...headers,
            ...(extra?.headers || {}),
        },
    };

    // Используем дополнительный обработчик перед отправкой запроса
    const beforeRequestProcessor = NetworkManager.config?.beforeRequestProcessor;
    if (typeof beforeRequestProcessor === 'function') {
        await beforeRequestProcessor(requestConfig);
    }

    const resp = await axios(requestConfig);

    return resp.data as TResponse;
}

/**
 * Выполнение GET запроса.
 *
 * @param path Путь АПИ запроса.
 * @param queryParams Дополнительные query параметры для совершения запроса.
 * @returns Ответ от сервера.
 *
 * @category Services
 */
export async function apiGetRequest<TResponse = any>(path, queryParams = {}) {
    return apiRequest<TResponse>('get', path, queryParams);
}

/**
 * Выполнение POST запроса.
 *
 * @param path Путь АПИ запроса.
 * @param data Данные, которые будут отправлены в теле запроса.
 * @param queryParams Дополнительные query параметры для совершения запроса.
 * @returns Ответ от сервера.
 *
 * @category Services
 */
export async function apiPostRequest<TResponse = any>(path, data, queryParams = {}, extra: Partial<AxiosRequestConfig> = {}) {
    return apiRequest<TResponse>('post', path, queryParams, data, {
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        ...extra,
    });
}

/**
 * Выполнение PUT запроса.
 *
 * @param path Путь АПИ запроса.
 * @param data Данные, которые будут отправлены в теле запроса.
 * @param queryParams Дополнительные query параметры для совершения запроса.
 * @returns Ответ от сервера.
 *
 * @category Services
 */
export async function apiPutRequest<TResponse = any>(path, data, queryParams = {}, extra: Partial<AxiosRequestConfig> = {}) {
    return apiRequest<TResponse>('put', path, queryParams, data, {
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        ...extra,
    });
}

/**
 * Выполнение DELETE запроса.
 *
 * @param path Путь АПИ запроса.
 * @param data Данные, которые будут отправлены в теле запроса.
 * @param queryParams Дополнительные query параметры для совершения запроса.
 * @returns Ответ от сервера.
 *
 * @category Services
 */
export async function apiDeleteRequest<TResponse = any>(path, data = {}, queryParams = {}, extra: Partial<AxiosRequestConfig> = {}) {
    return apiRequest<TResponse>('delete', path, queryParams, data, {
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        ...extra,
    });
}

function isFileList(fieldValue): boolean {
    // В ReactNative FileList не определен, поэтому там используется другой тип
    if (typeof FileList === 'undefined') {
        return fieldValue instanceof PolyfillFileList;
    }

    return fieldValue instanceof FileList;
}

/**
 * Выполнение POST запроса в формате отправки `multipart/form-data` данных.
 * Например для загрузки файлов вместе с JSON данными.
 *
 * @param path Путь АПИ запроса.
 * @param data Данные, которые должны быть отправлены в теле запроса.
 * @returns Ответ от сервера.
 *
 * @category Services
 */
export async function apiPostMultipart<TResponse = any>(path, data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        if (isFileList(value)) {
            for (let idx = 0; idx < value.length; idx++) {
                formData.append(key, value.item(idx));
            }
        } else {
            formData.append(key, value);
        }
    });

    return apiPostRequest<TResponse>(path, formData, undefined, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

/**
 * Отправляет POST запрос со списком файлов.
 *
 * @param path Путь АПИ запроса.
 * @param fileList Список файлов для отправки.
 * @returns Ответ от сервера.
 *
 * @category Services
 */
export async function apiUploadFiles<TResponse = any>(path, fileList) {
    const formData = new FormData();
    fileList.forEach(({
        key,
        file,
    }) => formData.append(key, file));

    return apiPostRequest<TResponse>(path, formData, undefined, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
